import React, { useEffect, useState } from 'react';
import Turnstile from 'react-turnstile';

import { useVerifyTurnstileTokenMutation } from '../../__generated__/gateway-graphql';
import { useEnvConfig } from '../../env-config';
import { useAppDispatch } from '../../store/store';
import { subscriptionActions } from '../../subscription-model/subscription.slice';

export const TurnstileContainer = () => {
    const siteKey = useEnvConfig().TURNSTILE_SITEKEY;
    const [firstToken, setFirstToken] = useState<string>('');
    const dispatch = useAppDispatch();
    const { mutateAsync: VerifyTurnstileToken } = useVerifyTurnstileTokenMutation();

    useEffect(() => {
        const handleTurnstileTokenVerify = async () => {
            const getTokenResponse = await VerifyTurnstileToken({ input: firstToken });

            if (!getTokenResponse) {
                throw new Error('Token not found');
            }

            dispatch(subscriptionActions.updateTurnstileToken({
                isTokenValid: getTokenResponse.verifyTurnstileToken
            }));
        };

        handleTurnstileTokenVerify();
    }, [VerifyTurnstileToken, dispatch, firstToken]);

    return (
        <Turnstile
            execution={'render'}
            appearance={'always'}
            theme={'light'}
            onVerify={setFirstToken}
            sitekey={siteKey}
        />
    )
    ;
};
