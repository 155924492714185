/**
 * App specific color tokens that don't belong in the global product theme.
 */
export const white = '#ffffff';

export const campaignTheme = {
    primary: 'linear-gradient(144deg, #5E5E5E -26.6%, #000 138.23%)',
    secondary: 'linear-gradient(266deg, #FF5757 4.46%, #D80213 44.92%, #FF5757 105.45%), #E22837',
    tertiary: white,
    hover: 'linear-gradient(144deg, #777 -26.6%, #1A1A1A 138.23%)'
};
