import { t } from '@lingui/macro';
import { useState } from 'react';

import { isStringDefined } from '../__utils__/utils';
import type { GetPlansDataQueryData } from '../api/gateway/onboarding-data-contract';
import { useActiveLocation } from '../location/use-active-location';
import { useAppSelector } from '../store/store';
import { usePlansDataWithTextQuery } from './use-plans-data-with-text-query';

type Hook = {
    productItems: GetPlansDataQueryData['plan']['productItems']
    childrenCount: number
    cardHeadingText: string
    onSetChildrenCount: (childrenCount: number) => any
    isLoading: boolean
    isSuccess: boolean
    error?: any
    isError: boolean
    currencyCode: string
    internalCoupon: GetPlansDataQueryData['plan']['internalCoupon']
};

/**
 * This gets the currencyCode from graphql ip location in BE,
 * see useActiveLocation. It then returns usePlansDataWithTextQuery.
 */
export function usePlansIpLocation (): Hook {
    const location = useActiveLocation();
    // Allow this query to be "enabled" only when currencyCode is available
    // the query will be marked as inactive and Gray in the devtools initially.
    const enabled = isStringDefined(location.data?.country?.currencyCode);
    // as string here won't matter as enabled flag won't query it.
    const currencyCode = location.data?.country?.currencyCode as string;
    const subscription = useAppSelector(
        state => state.subscription
    );
    const [childrenCount, setChildrenCount] = useState<number>(
        subscription.childrenCount
    );
    const {
        data, isLoading, isSuccess, error, isError
    } = usePlansDataWithTextQuery({
        excludeTrials: false,
        enabled,
        currencyCode,
        childrenCount
    });
    const productItems = data?.plan?.productItems || [];
    const internalCoupon = data?.plan?.internalCoupon || null;
    const isCampaignActive = isStringDefined(internalCoupon);
    const cardHeadingText = isCampaignActive ? t`Pricing Page - Card Heading Campaign Text` : t`Pricing Page - Card Heading`;

    return {
        isSuccess,
        error,
        isError,
        isLoading,
        internalCoupon,
        currencyCode,
        productItems,
        childrenCount,
        cardHeadingText,
        onSetChildrenCount: setChildrenCount
    };
}
