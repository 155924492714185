import { t } from '@lingui/macro';
import { useMemo } from 'react';

import type { ProductItem } from '../__generated__/gateway-graphql';
import { isStringDefined } from '../__utils__/utils';
import type { ProductItemWithText, GetPlansDataQueryData } from '../api/gateway/onboarding-data-contract';
import { useTPrice } from '../i18n/t-price/use-t-price';
import type { TPriceOptions } from '../i18n/t-price/use-t-price-polyfiller';
import { pickCountryFromCurrencyCode } from '../location/__utils__/pick-country-from-currency-code';
import { useGetCountriesQuery } from '../location/use-get-countries-query';
import { usePlansDataQuery } from './use-plans-data';

type Options = {
    currencyCode: string
    excludeTrials?: boolean
    enabled?: boolean
    childrenCount: number
};

/**
 * The main hook to retrieve the required Chargebee subscriptions plan data
 * and all aggregate the required marketing copy text from Contentful.
 */
export function usePlansDataWithTextQuery (options: Options) {
    const { currencyCode, enabled = true, childrenCount } = options;
    const tPrice = useTPrice();
    const planDataQuery = usePlansDataQuery({
        currencyCode,
        excludeTrials: options.excludeTrials || false,
        enabled
    });
    const productItems: Array<ProductItemWithText> = planDataQuery.data?.plan?.productItems as any;
    const countriesQuery = useGetCountriesQuery();
    const countries = countriesQuery.data?.countries || [];
    const country = typeof currencyCode === 'string' ? pickCountryFromCurrencyCode({ currencyCode }, countries) : null;
    const isDataReady = Array.isArray(productItems) && Array.isArray(countriesQuery.data?.countries);
    const productItemsData = useMemo(() => {
        if (!Array.isArray(productItems) || !country) {
            return null;
        }

        return productItems.map(item => mapProductItemText(
            country.countryCode,
            currencyCode,
            item,
            childrenCount,
            tPrice
        ));
    }, [
        tPrice,
        productItems,
        country,
        currencyCode,
        childrenCount
    ]);
    const isLoading = !isDataReady || planDataQuery.isLoading;
    const data: null | GetPlansDataQueryData = isLoading ? null : {
        ...planDataQuery.data,
        plan: {
            ...planDataQuery?.data?.plan as any,
            productItems: productItemsData
        }
    };
    const isSuccess = planDataQuery.isSuccess || countriesQuery.isSuccess;
    const isError = planDataQuery.isError || countriesQuery.isError;
    const error = planDataQuery.error || countriesQuery.error || null;

    return {
        isError,
        error,
        isLoading,
        isSuccess,
        data
    };
}

export function mapProductItemText (
    countryCode: string,
    currencyCode: string,
    item: ProductItem,
    childrenCount: number,
    tPrice: (options: TPriceOptions) => string
): ProductItemWithText {
    const {
        priceList, internalCoupon
    } = item;
    const isCampaignActive = isStringDefined(internalCoupon);
    const price = tPrice({
        // use zero index for 1 child amount in priceList
        amount: priceList[0].discountedAmount,
        countryCode,
        currencyCode
    });
    const { isTrial, subscriptionDuration, durationType, subscriptionType } = item;
    const cardHeadingText = isCampaignActive && subscriptionType !== 'Monthly' ?
        t`Product - Campaign - Annual - Highlight` : t`Product - Annual - Highlight`;

    if (isTrial && subscriptionDuration && durationType) {
        const trialButtonText = t({
            id: 'Start a {subscriptionDuration} {durationType} free trial',
            values: {
                subscriptionDuration,
                durationType
            }
        });
        const trialInclusionsLine1 = t({
            id: '{subscriptionDuration} {durationType} free trial',
            values: {
                subscriptionDuration,
                durationType
            }
        });
        const trialInclusionsLine2 = t({
            id: 'on {subscriptionType} Subscriptions',
            values: {
                subscriptionType
            }
        });

        return {
            ...item,
            countryCode,
            currencyCode,
            productHighlight: t`Product - Trial - Highlight`,
            paymentDisplay: t({
                id: '{subscriptionType} Subscription',
                values: {
                    subscriptionType
                }
            }),
            subscriptionPaymentOption: t`Product - Trial - Payment Option`,
            offer: t({
                id: 'Includes {subscriptionDuration} {durationType} free trial',
                values: {
                    subscriptionDuration,
                    durationType
                }
            }),
            ribbonText: t({
                id: '{subscriptionDuration} {durationType} free trial',
                values: {
                    subscriptionDuration,
                    durationType
                }
            }),
            headerTextForSingleChild: t({
                id: 'Free for {trialDuration} {trialDurationType} then {price} {currencyCode} per child per month, cancel anytime.',
                values: {
                    price,
                    currencyCode,
                    trialDuration: subscriptionDuration,
                    trialDurationType: durationType
                }
            }),
            headerTextForMultipleChild: t({
                id: 'Free for {trialDuration} {trialDurationType} then {price} {currencyCode} per month, cancel anytime.',
                values: {
                    price,
                    currencyCode,
                    trialDuration: subscriptionDuration,
                    trialDurationType: durationType
                }
            }),
            trialButtonText,
            trialInclusionsLine1,
            trialInclusionsLine2
        };
    }

    return {
        ...item,
        countryCode,
        currencyCode,
        productHighlight: cardHeadingText,
        paymentDisplay: t({
            id: '{subscriptionType} Subscription',
            values: {
                subscriptionType
            }
        }),
        subscriptionPaymentOption: t`Product - Annual - Payment Option`,
        offer: t`Product - Annual - Offer`,
        ribbonText: isCampaignActive ? t`Product - Campaign - Ribbon Text` : null,
        headerTextForSingleChild: t({
            id: '{price} {currencyCode} per child per year, cancel anytime.',
            values: {
                price,
                currencyCode
            }
        }),
        headerTextForMultipleChild: t({
            id: '{price} {currencyCode} per year, cancel anytime.',
            values: {
                price,
                currencyCode
            }
        }),
        trialButtonText: null,
        trialInclusionsLine1: null,
        trialInclusionsLine2: null
    };
}
