import { RippleButton } from '@3plearning/chakra-mathletics-theme';
import { Center, Flex, Box, Image } from '@chakra-ui/react';
import React from 'react';

import type { ProductItemWithText } from '../../../api/gateway/onboarding-data-contract';
import { campaignTheme } from '../../../styles/theme/foundations/colors';
import childIconSrc from '../../assets/kids.svg';
import { StyleVars } from '../../style-vars';
import { SweetenerLabel } from './components/sweetener-label';
import { usePricingCard } from './use-pricing-card';

type Props = {
    isTrial: boolean
    product: ProductItemWithText
    childrenCount: number
    subscribeChosenRoute: string
    setRRPValue: (value: any) => any
    setShowSavings: (value: any) => any
    isCampaignActive: boolean
};

export function PricingCard ({
    isTrial,
    product,
    childrenCount,
    subscribeChosenRoute,
    setRRPValue,
    setShowSavings,
    isCampaignActive
}: Props) {
    const {
        heading,
        basePrice,
        showSavings,
        periodLabel,
        offerLabel,
        childrenCountText,
        currencyCode,
        subscriptionType,
        onSubscribe
    } = usePricingCard({
        product,
        childrenCount,
        subscribeChosenRoute,
        setRRPValue,
        setShowSavings
    });
    const isMonthlyPlan = subscriptionType === 'Monthly';
    const yearlySubscriptionLabelColor = isMonthlyPlan ?
        'secondary.50' : isCampaignActive ?
            campaignTheme.secondary : 'primary.50';
    const yearlySubscriptionBorderColor = isMonthlyPlan ?
        'secondary.500' : isCampaignActive ?
            'transparent' : 'primary.500';
    const color = isCampaignActive && !isMonthlyPlan ? campaignTheme.secondary : 'primary.600';
    const bgColor = isCampaignActive && !isMonthlyPlan ?
        campaignTheme.primary :
        'linear-gradient(144.5deg, #0063AC -26.6%, #00ADEE 138.23%)';

    return (
        <Flex
            data-testid={'pricing-card'}
            height={'max-content'}
            width={{
                mobile: StyleVars.pricingCardMobileWidth,
                pricingTablet: StyleVars.pricingCardWidth
            }}
            background={'white'}
            border={'2px solid'}
            borderColor={color}
            boxSizing={'border-box'}
            boxShadow={'0px 0px 12px rgba(161, 161, 161, 0.3)'}
            borderRadius={'20px'}
            flexDirection={'column'}
            alignItems={'center'}
            marginBottom={{
                mobile: '16px',
                tablet: '24px'
            }}
        >
            <Center
                marginBottom={{
                    tablet: '24px',
                    desktop: '32px'
                }}
                width={'calc(100% + 4px)'}
                position={'relative'}
                bottom={'2px'}
                height={'48px'}
                color={'white'}
                background={bgColor}
                borderRadius={'20px 20px 0px 0px'}
                fontWeight={'500'}
                fontSize={'18px'}
                lineHeight={'24px'}
            >
                {heading}
            </Center>

            <Box
                as={'div'}
                display={'inline-grid'}
                fontSize={'36px'}
                lineHeight={'36px'}
                fontWeight={'600'}
                color={'neutral.900'}
                margin={'0px 36px 8px 36px'}
                textAlign={'center'}
                width={'unset'}
            >
                {basePrice}

                <Box
                    as={'span'}
                    display={'contents'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    fontWeight={'medium'}
                >
                    {currencyCode}
                </Box>
            </Box>

            <Box
                as={'div'}
                color={color}
                fontSize={'20px'}
                lineHeight={'29px'}
                fontWeight={'medium'}
                marginBottom={'16px'}
            >
                {periodLabel}
            </Box>

            <Center
                width={'100%'}
                height={'40px'}
                borderTop={'1px solid'}
                borderTopColor={yearlySubscriptionBorderColor}
                borderBottom={'1px solid'}
                borderBottomColor={yearlySubscriptionBorderColor}
                background={yearlySubscriptionLabelColor}
                marginBottom={'21px'}
                position={'relative'}
            >
                <SweetenerLabel
                    isCampaignActive={isCampaignActive}
                    offerLabel={isTrial ? offerLabel : showSavings}
                    isTrial={product.isTrial}
                    subscriptionType={subscriptionType}
                />
            </Center>

            <Center
                fontSize={'16px'}
                lineHeight={'neutral.600'}
                color={'neutral.600'}
            >
                <Image
                    width={'30px'}
                    height={'20px'}
                    marginRight={{
                        mobile: '14px',
                        tablet: '10px'
                    }}
                    alt={offerLabel}
                    src={childIconSrc}
                />
                {childrenCountText}
            </Center>

            <RippleButton
                margin={{
                    mobile: '24px 0 32px 0',
                    tablet: '20px 0 24px 0'
                }}
                width={{
                    mobile: '200px',
                    pricingTablet: '238px'
                }}
                height={'52px'}
                variant={'primary'}
                size={'large'}
                onClick={onSubscribe}
                data-planid={product.productCode}
                data-component={'subscribe-button'}
                data-testid={`${product.subscriptionType}-subscribe-button`}
                bg={isCampaignActive && !isMonthlyPlan ? campaignTheme.primary : 'primary.600'}
                _hover={{
                    bg: isCampaignActive && !isMonthlyPlan ? campaignTheme.hover : 'primary.700'
                }}
            >
                Subscribe
            </RippleButton>
        </Flex>
    );
}
