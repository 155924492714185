/**
 * Type guard for use in a filter for undefined or null values.
 */
export function notEmpty (value: any) {
    return !isEmpty(value);
}

/**
 * Guard against empty values, null, undefined or empty string.
 */
export function isEmpty (value: any) {
    return (
        value === null || value === undefined || value === ''
    );
}

export function isStringDefined (value: any): value is string {
    return isString(value) && value.length > 0;
}

export function isString (value: any): value is string {
    return typeof value === 'string';
}

export function isObject<TValue=unknown> (input: unknown): input is Record<string, TValue> {
    return (
        typeof input === 'object' &&
        input !== null &&
        !Array.isArray(input)
    );
}

/**
 * Very basic debounce implementation to limit sync subsequent calls on a method.
 */
export function debounce (debounceMethod: (...args: any[]) => any, timeout = 1000){
    let timer: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            // @ts-ignore this is problematic to type
            debounceMethod.apply(this, args);
        }, timeout);
    };
}

export function isBoolean (value: any): value is boolean {
    return typeof value === 'boolean';
}

export const INVALID_EMAIL = 'Invalid email';

export const REQUIRED_EMAIL = 'Your email is required';

export const USER_ATTEMPT_MAX_LIMIT_REACHED = 'USER_ATTEMPT_MAX_LIMIT_REACHED';
